.intro-screen {
    background-image: url('../../images/bg.png');
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.text-bg {
    background-image: url('../../images/text-bg.png');
    background-size: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    width: 200px;
    height: 100px;
    margin-left: 300px;
}

.heading {
    font: Nunito;
    color: #11AEC6;
    font-weight: bold;
    font-size: 22px;
}

.monkey-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.monkey {
    height: 60vh;
    width: 30vw;
}

.link-start {
    display: flex;
    flex-direction: row;
    justify-content: end;
    width: 90vw;
}

.start-button {
    background-image: url('../../images/button.png');
    background-size: cover;
    width: 230px;
    height: 75px;
}