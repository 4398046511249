.instruction-screen{
    background-image: url('../../images/bg.png');
    background-size: cover;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.back-link {
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 90vw;
}

.back-button {
    background-image: url('../../images/back-button.png');
    background-size: cover;
    height: 75px;
    width: 75px;
}

.card-instructions {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100vw;
}

.cards {
    width: 350px;
    height: 450px;
    border-radius: 80px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.card-1-bg {
    background-image: url('../../images/card-1-bg.png');
    background-size: cover;
    width: 200px;
    height: 300px;
    margin-right: 20px;
}

.card-1 {
    margin-left: 20px;
    margin-bottom: 15px;
}

.card-2-bg {
    background-image: url('../../images/card-2-bg.png');
    background-size: fitting;
    background-repeat: no-repeat;
    width: 200px;
    height: 300px;
}

.card-3-bg {
    max-height: 200px;
}

.card-text {
    font-size: 30px;
    text-align: center;
}

.text {
    text-align: center;
    font-size: 16px;
}

.play-link {
    display: flex;
    flex-direction: row;
    justify-content: end;
    width: 90vw;
}

.play-button {
    background-image: url('../../images/play-button.png');
    background-size: cover;
    width: 190px;
    height: 75px;
}