.activity-screen{
    background-image: url('../../images/bg.png');
    background-size: cover;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.game-cards-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-items: center;
    gap: 30px;
}

.scoreboard {
    display: flex;
    justify-content: space-between;
    width: 350px;
    margin-bottom: 10px;
}

.board {
    display: flex;
    width: 100vw;
    height: 70vh;
    flex-direction: row;
    justify-content: center;
    gap: 100px;
    align-items: center;
    flex-wrap: wrap;
  }

  .board-left {
    display: flex;
    flex-direction: row;
    width: 500px;
    flex-wrap: wrap;
    gap: 50px;
  }
  
  .board-right {
    display: flex;
    flex-direction: row;
    width: 500px;
    flex-wrap: wrap;
    gap: 50px;
  }