.modalPP-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #9c7b05;
    font-weight: bolder;
    font-size: 22px;
  }

  .modalPP {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #FFF5D1;
    border-radius: 10px;
    max-width: 400px;
    width: 100%;
    max-height:300px;
    height: 100%;
    position: relative;
  }

  .match-text{
    color: #EF8F35;
    font-size: 32px;
  }