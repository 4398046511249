.card {
    width: 130px;
    height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #fff; */
    cursor: pointer;
    border-radius: 5px;
  }
  
  .card-content {
    font-size: 24px;
    color: #282c34;
  }

  .open-card {
    transform: rotate(180) 0.5s;
    width: 165px;
    height: 215px;
  }